<div class="logo-container">
  <button class="logo-button" mat-button routerLink="/dashboard"></button>
</div>
<mat-nav-list>
  @for (menuItem of userMenu; track menuItem) {
    <a
      (click)="actionMenu(menuTrigger, 'open')"
      (mouseenter)="actionMenu(menuTrigger, 'open')"
      (mouseleave)="actionMenu(menuTrigger, 'close')"
      mat-list-item>
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
      <mat-menu #menu="matMenu" [hasBackdrop]="false">
        <div
          (mouseenter)="actionMenu(menuTrigger, 'open')"
          (mouseleave)="actionMenu(menuTrigger, 'close')">
          <div class="menu-title">{{ menuItem.name }}</div>
          <mat-divider></mat-divider>
          <!-- TODO: This /dashboard should not be hardcoded -->
          @for (subitem of menuItem.items; track subitem) {
            <a
              [routerLink]="'/dashboard/' + menuItem.uri + '/' + subitem.uri"
              mat-menu-item>
              {{ subitem.name }}
            </a>
          }
        </div>
      </mat-menu>
    </a>
  }
</mat-nav-list>
@if (appData) {
  <p class="app-version">
    {{ appData.version }}
  </p>
}
