import { storageFactory } from './app/app.module';
import { AppComponent } from './app/app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from './environments/environment';
import { GarrampaApiServicesModule } from '@garrampa/api-services';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { CustomTitleStrategy } from './app/core/title-strategy/custom-title-strategy';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import { ErrorInterceptor } from './app/shared/interceptors/error.interceptor';
import { LoadingInterceptor } from './app/shared/interceptors/loading.interceptor';
import { AuthInterceptor } from './app/core/auth/auth.interceptor';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_ROUTES } from './app/app.routes';
import {
  NoPreloading,
  provideRouter,
  TitleStrategy,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateModule,
} from '@angular/material-luxon-adapter';
import { provideMarkdown } from 'ngx-markdown';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      OAuthModule.forRoot(),
      GarrampaApiServicesModule.forRoot({
        apiUrl: environment.apiUrl,
      }),
      MatSnackBarModule,
      MatDialogModule,
      MatLuxonDateModule,
    ),
    provideRouter(
      APP_ROUTES,
      withPreloading(NoPreloading),
      withComponentInputBinding(),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideMarkdown({ loader: HttpClient }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES',
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true, firstDayOfWeek: 1 },
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
  ],
}).catch(err => console.error(err));
