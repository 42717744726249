{
  "name": "gafe",
  "version": "2024.7.2-beta",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "prepare": "husky",
    "lint:fix": "ng lint --fix",
    "prettier": "npx lint-staged && npm run lint:fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.1",
    "@angular/cdk": "^18.0.1",
    "@angular/common": "^18.0.1",
    "@angular/compiler": "^18.0.1",
    "@angular/core": "^18.0.1",
    "@angular/forms": "^18.0.1",
    "@angular/material": "^18.0.1",
    "@angular/platform-browser": "^18.0.1",
    "@angular/platform-browser-dynamic": "^18.0.1",
    "@angular/router": "^18.0.1",
    "@garrampa/api-services": "^2024.7.0-beta.0",
    "@garrampa/material-table": "^2024.6.2",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "angular-oauth2-oidc": "^17.0.2",
    "luxon": "^3.4.4",
    "marked": "^12.0.2",
    "ngx-markdown": "^18.0.0",
    "ngx-mat-select-search": "^7.0.6",
    "ngx-papaparse": "^8.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "^0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.2",
    "@angular-eslint/builder": "18.0.1",
    "@angular-eslint/eslint-plugin": "18.0.1",
    "@angular-eslint/eslint-plugin-template": "18.0.1",
    "@angular-eslint/schematics": "18.0.1",
    "@angular-eslint/template-parser": "18.0.1",
    "@angular/cli": "~18.0.2",
    "@angular/compiler-cli": "^18.0.1",
    "@angular/material-luxon-adapter": "^18.0.1",
    "@commitlint/cli": "^19.3.0",
    "@commitlint/config-conventional": "^19.2.2",
    "@types/jasmine": "^5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.14.0",
    "@typescript-eslint/eslint-plugin": "^7.11.0",
    "@typescript-eslint/parser": "^7.11.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "file-saver": "^2.0.5",
    "husky": "^9.0.11",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "lint-staged": "^15.2.5",
    "prettier": "^3.3.0",
    "prettier-eslint": "^16.3.0",
    "typescript": "^5.4"
  },
  "volta": {
    "node": "20.11.1"
  }
}
