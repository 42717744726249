import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterLink,
} from '@angular/router';
import {
  NoteGetMyNotesResponseModel,
  NoteRepository,
} from '@garrampa/api-services';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../../../shared/components/notes-dialog/notes-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarHelpDialogComponent } from './dialogs/help-dialog/toolbar-help-dialog.component';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    ToolbarSearchComponent,
    MatTooltipModule,
    MatMenuModule,
    MatListModule,
  ],
})
export class ToolbarMenuComponent implements OnInit {
  @Output()
  clickMenuButton = new Subject<void>();

  @ViewChildren(MatMenuTrigger) triggers?: QueryList<MatMenuTrigger>;

  currentUserNotes: NoteGetMyNotesResponseModel[] = [];

  constructor(
    private eRef: ElementRef,
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private noteRepo: NoteRepository,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loadMyNotes();
  }

  // Listen to clicks in the browser
  @HostListener('document:click', ['$event'])
  browserClick(event: Event) {
    // Click outside the component:
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeAllMenus();
    }
  }

  logout(): void {
    this.oauthService.logOut();

    this.router.navigateByUrl('/');
  }

  openAccountSettings(): void {
    void this.router.navigateByUrl('/dashboard/account');
  }

  openNote(id: number): void {
    const dialog = this.dialog.open(NotesDialogComponent, {
      data: { origin: 'userNotes', id },
      minWidth: '50%',
    });

    dialog.afterClosed().subscribe(hasToReload => {
      if (hasToReload) {
        this.loadMyNotes();
      }
    });
  }

  createNote(): void {
    const dialog = this.dialog.open(NotesDialogComponent, {
      data: { origin: 'userNotes' },
      minWidth: '50%',
    });

    dialog.afterClosed().subscribe(hasToReload => {
      if (hasToReload) {
        this.loadMyNotes();
      }
    });
  }

  openHelpDialog(): void {
    // Get markdown path
    const getLastChild = (route: ActivatedRouteSnapshot) => {
      let child = route;
      while (child.firstChild) {
        child = child.firstChild;
      }
      return child;
    };

    const primary = this.route.snapshot.root; // Get the primary route
    const lastChild = getLastChild(primary); // Get the last child (from which you want the params).
    const routeData = lastChild.data;

    let markdownPath: string | undefined = undefined;

    if (
      routeData['sectionData'] &&
      routeData['sectionData'].view &&
      routeData['mode']
    ) {
      markdownPath = `/assets/help-docs${routeData['sectionData'].view}/${routeData['mode']}.md`;
    }

    this.dialog.open(ToolbarHelpDialogComponent, {
      data: { markdownUrl: markdownPath },
    });
  }

  private closeAllMenus(): void {
    this.triggers?.forEach(trigger => {
      trigger.closeMenu();
    });
  }

  private loadMyNotes(): void {
    this.noteRepo.getMyNotes().subscribe(data => {
      this.currentUserNotes = data.values;
    });
  }
}
